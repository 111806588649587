import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Career',
  introTitle: 'What can you expect at freshcells',
  introInfo: '<p>Designing, developing, and maintaining e-commerce applications is our business. Cutting-edge technologies, customers who want more than average, and a fun, vibrant team of over 40 web experts aged 20 to 50+ from five countries – plus seven office dogs. We also have a stylish office and rec room on the spacious premises of a former carpet factory in the heart of Dusseldorf. OK, it’s sometimes a little warm in summer, but then you can simply show up in a T-shirt and Bermuda shorts or work from home.</p><p>We are vibrant and want to stay that way, and we always look forward to welcoming new colleagues. You should be able to communicate in English. German would be an advantage, but it’s not necessarily required. We are not a hire-and-fire company; we prefer to quench our thirst with a cold drink after work. You’d make a great addition to our team? Then check out our current vacancies to see where we’re currently expanding.</p>',
  btn: 'Can I work with you?',
  promoTitle: 'Take up the challenge and become a part of the team',
  promoInfo: 'Every day at freshcells is exciting and brings new challenges. Our 70-strong, creative, motivated, multinational team successfully realizes online projects. This is only possible with the flat hierarchies, bundles of initiative, and the short decision paths of a privately owned company.',
  promoSubitle: 'Do you want to grow and develop yourself with us',
  promoInfo2: 'You’ll find a young, dynamic company of true team players at freshcells, where you can make your ideas a reality. All that’s missing is your application in our inbox.',
  promoBtn: 'Get in touch now'
})
