import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  title: 'The job you love',
  info: 'Here you will find a work atmosphere that gives you every opportunity to develop your talent and skills each day. Benefit from our experience and discover how you can contribute to the success of freshcells with your ideas.',
  managerTitle: 'Project Manager',
  managerInfo: 'As a project manager, you have a gift for organizing and keeping the overview. Consulting and communication are your passions',
  BusinessDeveloperSalesManagerTitle: 'Business Developer / Sales Manager',
  BusinessDeveloperSalesManagerInfo: 'Als Business Developer / Sales Manager wirst du Teil unseres New Business Teams und übernimmst den Lead in der initialen Kundenakquise',
  technicalManagerTitle: 'Technical Project Manager',
  technicalManagerInfo: 'As a TPM, you are the interface between project management and development. Your job is to understand and manage complex application development.',
  salesDirectorTitle: 'Sales Director',
  salesDirectorInfo: 'Your passion is sales, you have fun showing which business potentials can be raised by exceptionally good software development in different industries?.',
  softwareTesterTitle: 'Working Student Software Tester',
  softwareTesterInfo: 'As a Software Tester, you are responsible for keeping the level of quality as high as possible. Testing new features and applications with an eye for details is your job.',
  architectTitle: 'Senior Software Architect',
  architectInfo: 'As a Senior Software Architect you are responsible for our internal infrastructure aswell as the environment of our clients',
  fullstackDevTitle: 'Fullstack Developer',
  fullstackDevInfo: 'As a fullstack developer you work on innovative backend and frontend applications based on Docker, Kubernetes, Node.js and ReactJS',
  devOpsTitle: 'DevOps Engineer',
  devOpsInfo: 'As a dedicated DevOps engineer you will be responsible for setting up clusters (from development to production environments), taking care of backups and security, monitoring and delivery process',
  designerTitle: 'Senior UI/UX Designer',
  designerInfo: 'Beautiful UIs are mandatory. But you dig deeper. You are able to translate requirements into delightful application concepts.',
  dataProtectionPdfPath: 'https://drive.google.com/open?id=14n38oPXxp1yBIw_dK2qA680GgoHbidGq',
  dataProtectionPdfLink: 'The privacy policy for applicants can be found here.',
  softwareArchitectTitle: 'Senior Software Architect',
  softwareArchitectInfo: 'As a software architect you understand trends and make long term technical decisions',
  accountantTitle: 'Accountant',
  accountantInfo: 'As an accountant you are responsible for all finances and budget plannings.',
  receptionistTitle: 'Empfang & Büro Management',
  receptionistInfo: 'Um unser Team in Düsseldorf zu verstärken, sind wir auf der Suche nach Unterstützung für Empfang & Büro Management in Teilzeit.',
  seniorJavaDeveloperTitle: 'Senior Java Developer',
  seniorJavaDeveloperInfo: 'Join us on our exciting journey.',
  seniorPHPFullStackTitle: 'Senior Full Stack Developer PHP',
  seniorPHPFullStackInfo: 'Join us on our exciting journey.',
  frontendReactTitle: 'Frontend Developer React',
  frontendReactInfo: 'Join us on our exciting journey.',
  frontendDesignerTitle: 'Frontend Designer',
  frontendDesignerInfo: 'As a Frontend Designer you are responsible for the frontend implementation of all design concepts and bring them to life.',
  hrGeneralistTitle: 'HR Generalist /  Personalreferent',
  hrGeneralistInfo: 'Als HR Generalist/Personalreferent umfasst dein Aufgabengebiet sowohl den gesamten Recruiting-Prozess als auch die Etablierung und Verbesserung sämtlicher HR-relevanter Prozesse.',
  contentManagerTitle: 'Content Manager',
  contentManagerInfo: 'Als Content Manager hilfst du uns bei der Erstellung und Verarbeitung von fallen internen und externen Inhalten.',
  seniorUiUxDesignerTitle: '(Senior) UI / UX Designer',
  seniorUiUxDesignerInfo: 'Als UI/UX Designer bist du für die konzeptionelle Entwicklung komplexer Webapplikationen verantwortlich.'
})
