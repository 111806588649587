// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import ListItem from '../../../components/basic/ListItem'
import messages from './Vacancy.lang'
import baseStyles from './Vacancy.scss'

type Props = {
  ...StyleProps
}

const Vacancy = ({ styles, ...rest }: Props) => {
  const { formatMessage } = useIntl()

  return (
    // $FlowFixMe[cannot-spread-inexact]
    <ListItem styles={styles.root} btn={formatMessage(messages.btn)} {...rest} />
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Vacancy)
