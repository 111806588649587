// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Carousel from '../../../components/basic/Carousel'
import messages from './TeamGallery.lang'
import baseStyles from './TeamGallery.scss'

const img1 = <StaticImage src="../../../images/career/StripeImg-1.jpg" alt="" objectFit="cover" className="image" />
const img2 = <StaticImage src="../../../images/career/StripeImg-2.jpg" alt="" objectFit="cover" className="image" />
const img3 = <StaticImage src="../../../images/career/StripeImg-3.jpg" alt="" objectFit="cover" className="image" />
const img4 = <StaticImage src="../../../images/career/StripeImg-4.jpg" alt="" objectFit="cover" className="image" />
const img5 = <StaticImage src="../../../images/career/StripeImg-5.jpg" alt="" objectFit="cover" className="image" />
const img6 = <StaticImage src="../../../images/career/StripeImg-6.jpg" alt="" objectFit="cover" className="image" />
const img7 = <StaticImage src="../../../images/career/StripeImg-7.jpg" alt="" objectFit="cover" className="image" />
const img8 = <StaticImage src="../../../images/career/StripeImg-8.jpg" alt="" objectFit="cover" className="image" />
const img9 = <StaticImage src="../../../images/career/StripeImg-9.jpg" alt="" objectFit="cover" className="image" />
const img10 = <StaticImage src="../../../images/career/StripeImg-10.jpg" alt="" objectFit="cover" className="image" />
const img11 = <StaticImage src="../../../images/career/StripeImg-11.jpg" alt="" objectFit="cover" className="image" />
const img12 = <StaticImage src="../../../images/career/StripeImg-12.jpg" alt="" objectFit="cover" className="image" />
const img13 = <StaticImage src="../../../images/career/StripeImg-13.jpg" alt="" objectFit="cover" className="image" />
const img14 = <StaticImage src="../../../images/career/StripeImg-14.jpg" alt="" objectFit="cover" className="image" />
const img15 = <StaticImage src="../../../images/career/StripeImg-15.jpg" alt="" objectFit="cover" className="image" />
const img16 = <StaticImage src="../../../images/career/StripeImg-16.jpg" alt="" objectFit="cover" className="image" />
const img17 = <StaticImage src="../../../images/career/StripeImg-17.jpg" alt="" objectFit="cover" className="image" />
const img18 = <StaticImage src="../../../images/career/StripeImg-18.jpg" alt="" objectFit="cover" className="image" />

type Props = {
  ...StyleProps,
  title?: string
}

const TeamGallery = ({ styles, title }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      {!title ? (
        <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
      ) : (
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      )}
      <Carousel
        styles={styles.slider}
        settings={{
          swipe: true,
          lazyLoad: 'progressive',
          infinite: true,
          dots: false,
          responsive: [
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1439,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                swipe: true
              }
            },
            {
              breakpoint: 9999,
              settings: {
                slidesToShow: 8,
                slidesToScroll: 1,
                swipe: false
              }
            }
          ]
        }}
      >
        {img1}
        {img2}
        {img3}
        {img4}
        {img5}
        {img6}
        {img7}
        {img8}
        {img9}
        {img10}
        {img11}
        {img12}
        {img13}
        {img14}
        {img15}
        {img16}
        {img17}
        {img18}
      </Carousel>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TeamGallery)
