// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../IntroTeaser'
import TeamGallery from './TeamGallery'
import Vacancies from './Vacancies'
import ScrollAnimation from 'react-animate-on-scroll'
import ClientOverview from '../Clients/ClientOverview'

import messages from './CareerPage.lang'
import baseStyles from './CareerPage.scss'
import { StaticImage } from 'gatsby-plugin-image'

const IntroImage = (
  <StaticImage src="../../images/vacancies/image_promo.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/career_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const CareerPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={HeroTeaserImage} imgAlt="freshcells team" />
      <IntroTeaser
        styles={styles.intro}
        title={formatMessage(messages.introTitle)}
        copy={<FormattedMessageSanitized {...messages.introInfo} />}
        image={IntroImage}
        btn={formatMessage(messages.btn)}
        btnIcon="icon-arrow_03-down-medium"
        btnAnchor={'Vacancies'}
        fullWidth
      />
      <ScrollAnimation className={styles.animationContainer} animateIn="fadeInUp" animateOnce delay={500}>
        <TeamGallery />
      </ScrollAnimation>
      <div id="Vacancies">
        <Vacancies />
      </div>
      <IntroTeaser
        styles={styles.promo}
        title={formatMessage(messages.promoTitle)}
        copy={
          <>
            <p>{formatMessage(messages.promoInfo)}</p>
            <h3 className={styles.subtitle}>{formatMessage(messages.promoSubitle)}</h3>
            <p>{formatMessage(messages.promoInfo2)}</p>
          </>
        }
        image={IntroImage}
        aLink="mailto: recruiting@freshcells.de"
        btn={formatMessage(messages.promoBtn)}
        fullWidth
      />
      <ClientOverview />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CareerPage)
